import Product from '@/models/product';
import Cart from '@/models/cart';
import {StyleConfig, CbAnchorElement, CbCallbacksInterface} from '@/interfaces/cb-types';
import Handler from '@/models/handler';
import {PageType} from '@/constants/enums';
import {PortalSession} from '@/interfaces/cb-types';
import CbInstanceOptions from '@/interfaces/cb-instance-options';
import Helpers from '@/helpers/index';
import AuthHandler from '@/models/auth-handler';
import Page from '@/models/page';
import ChargebeePortal from '@/models/chargebee-portal';
import {
  Component,
  ComponentType,
  ThreeDSHandler,
  IDealPayment,
  SofortPayment,
  GooglePayment,
  BancontactPayment,
  GiropayPayment,
  DotpayPayment,
  ApplePayment,
  PaypalPayment,
  NetbankingPayment,
  UpiPayment,
  DirectDebitPayment,
  BoletoPayment,
  TabRedirectPayments,
  PaymentOptions,
  PAYMENT_AUTH_REDIRECT_WINDOW_NAME,
  AmazonPayPayment,
  VenmoPayment,
  FasterPymtsPayment,
  PayToPayment,
  SepaInstantTransferPayment,
  KlarnaPayNowPayment,
  OnlineBankingPolandPayment,
} from '@/hosted_fields/common/base-types';
import Assert from '@/helpers/asserts';
import {PaymentIntent, AdditionalData, Callbacks} from '@/extensions/three_domain_secure/common/types';
import ComponentsAndFieldsLoaderInterface from '@/plugins/components_fields/loader/interface';
import ThreeDSLoaderInterface from '@/plugins/three_domain_secure/loader/interface';
import FunctionsPluginLoaderInterface, {
  EstimateFunctionsPluginLoaderInterface,
  VatValidationFunctionsPluginLoaderInterface,
} from '@/plugins/functions/loader/interface';
import {Info as JsInfo} from '@/plugins/checkout_utils/types';
import IframeClientLoader, {sendToMasterIframe} from '@/hosted_fields/host/iframe-client-loader';
import IDealPaymentLoaderInterface from '@/plugins/payments/iDeal/loader/interface';
import SofortPaymentLoaderInterface from '@/plugins/payments/sofort/loader/interface';
import GooglePaymentLoaderInterface from '@/plugins/payments/google_pay/loader/interface';
import BancontactPaymentLoaderInterface from '@/plugins/payments/bancontact/loader/interface';
import GiropayPaymentLoaderInterface from '@/plugins/payments/giropay/loader/interface';
import DotpayPaymentLoaderInterface from '@/plugins/payments/dotpay/loader/interface';
import PaypalPaymentLoaderInterface from '@/plugins/payments/paypal_express_checkout/loader/interface';
import NetbankingPaymentLoaderInterface from '@/plugins/payments/netbanking_emandates/loader/interface';
import ApplepayPaymentLoaderInterface from '@/plugins/payments/apple_pay/loader/interface';
import AmazonpayPaymentLoaderInterface from '@/plugins/payments/amazon_payments/loader/interface';
import UpiPaymentLoaderInterface from '@/plugins/payments/upi/loader/interface';
import DirectDebitPaymentLoaderInterface from '@/plugins/payments/direct_debit/loader/interface';
import FasterPymtsLoaderInterface from '@/plugins/payments/faster_payments/loader/interface';
import PayToPaymentLoaderInterface from '@/plugins/payments/pay_to/loader/interface';
import SepaInstantTransferPaymentLoaderInterface from '@/plugins/payments/sepa_instant_transfer/loader/interface';
import BoletoPaymentLoaderInterface from '@/plugins/payments/boleto/loader/interface';
import CbWindowManager from './cb-window-manager';
import {Locale, ResponseInnerMessage} from '@/hosted_fields/common/types';
import VenmoPaymentLoaderInterface from '@/plugins/payments/venmo/loader/interface';
import {Master} from '@/hosted_fields/common/enums';
import Ids from '@/constants/ids';
import {CaptchaProviderName, RecaptchaOptions} from '@/plugins/captcha/types';
import HostedPagePlugin from '@/plugins/hosted_page/host/';
import {CheckoutOptions} from '@/hosted_page/host/checkout/types';
import {Checkout} from '@/hosted_page/host/checkout';
import KlarnaPayNowLoaderInterface from '@/plugins/payments/klarna_pay_now/loader/interface';
import OnlineBankingPolandPaymentLoaderInterface from '@/plugins/payments/online_banking_poland/loader/interface';
import {Components} from '@chargebee/components';
import EnvConstants from '@/constants/environment';

export default class CbInstance {
  /** @internal */
  site: string;

  /** @internal */
  domain: string;

  /** @internal */
  authenticated: boolean;

  /** @internal */
  authHandler: AuthHandler;

  /** @internal */
  enableRedirectMode: boolean;

  /** @internal */
  enableGATracking: boolean;

  /** @internal */
  enableFBQTracking: boolean;

  /** @internal */
  enableRefersionTracking: boolean;

  /** @internal */
  enableGTMTracking: boolean;

  /** @internal */
  enableFriendbuyTracking: boolean;

  /** @internal */
  enableTopLevelRedirectOnSuccess: boolean;

  /** @internal */
  portalCallbacks: CbCallbacksInterface;

  /** @internal */
  publishableKey: string;

  /** @internal */
  recaptchaKey: string;

  /** @internal */
  isItemsModel: boolean;

  /** @internal */
  businessEntityId: string;

  /** @internal */
  cspNonce: string;

  /** @internal */
  hostedPagePlugin: HostedPagePlugin;

  /** @internal */
  componentLoader: ComponentsAndFieldsLoaderInterface;

  /** @internal */
  threeDSLoader: ThreeDSLoaderInterface;

  /** @internal */
  functionsPluginLoader: FunctionsPluginLoaderInterface;

  /** @internal */
  iDealPaymentLoader: IDealPaymentLoaderInterface;

  /** @internal */
  sofortPaymentLoader: SofortPaymentLoaderInterface;

  /** @internal */
  googlePaymentLoader: GooglePaymentLoaderInterface;

  /** @internal */
  bancontactPaymentLoader: BancontactPaymentLoaderInterface;

  /** @internal */
  giropayPaymentLoader: GiropayPaymentLoaderInterface;

  /** @internal */
  dotpayPaymentLoader: DotpayPaymentLoaderInterface;

  /** @internal */
  fasterPymtsPaymentLoader: FasterPymtsLoaderInterface;

  /** @internal */
  payToPaymentLoader: PayToPaymentLoaderInterface;

  /** @internal */
  sepaInstantTransferPaymentLoader: SepaInstantTransferPaymentLoaderInterface;

  /** @internal */
  paypalPaymentLoader: PaypalPaymentLoaderInterface;

  /** @internal */
  netbankingPaymentLoader: NetbankingPaymentLoaderInterface;

  /** @internal */
  applepayPaymentLoader: ApplepayPaymentLoaderInterface;

  /** @internal */
  upiPaymentLoader: UpiPaymentLoaderInterface;

  /** @internal */
  directDebitPaymentLoader: DirectDebitPaymentLoaderInterface;

  /** @internal */
  venmoPaymentLoader: VenmoPaymentLoaderInterface;

  /** @internal */
  boletoPaymentLoader: BoletoPaymentLoaderInterface;
  
  /** @internal */
  amazonpayPaymentLoader: AmazonpayPaymentLoaderInterface;

  /** @internal */
  klarnaPayNowLoader: KlarnaPayNowLoaderInterface;

  /** @internal */
  onlineBankingPolandPaymentLoader: OnlineBankingPolandPaymentLoaderInterface;

  /** @internal */
  options: CbInstanceOptions;

  /** @internal */
  checkoutCallbacks: (cart: Cart) => CbCallbacksInterface;

  /** @internal */
  styleConfig: StyleConfig = {};

  cart: Cart;

  /** @internal */
  estimates: EstimateFunctionsPluginLoaderInterface;

  /** @internal */
  vat: VatValidationFunctionsPluginLoaderInterface;

  /** @internal */
  hp_token: string;

  /** @internal */
  constructor(options: CbInstanceOptions) {
    this.site = options.site;
    this.options = options;
    if (!this.site) {
      throw new Error('Site name is not set');
    }
    this.domain = options.domain;
    this.publishableKey = options.publishableKey;
    this.enableRedirectMode = options.enableRedirectMode;
    this.enableTopLevelRedirectOnSuccess = options.enableTopLevelRedirectOnSuccess;
    this.enableGATracking = options.enableGATracking;
    this.enableFBQTracking = options.enableFBQTracking;
    this.enableRefersionTracking = options.enableRefersionTracking;
    this.enableFriendbuyTracking = options.enableFriendbuyTracking;
    this.enableGTMTracking = options.enableGTMTracking;
    this.recaptchaKey = options.recaptchaKey;
    this.isItemsModel = options.isItemsModel;
    this.cspNonce = options.cspNonce;
    let cbContainer = Helpers.createContainer();
    cbContainer.cbInstance = this;
    this.authHandler = new AuthHandler(this);
    this.cart = new Cart();
    this.cart.setBusinessEntity(options.businessEntityId);
    options.portalSession && this.setPortalSession(options.portalSession);

    // Initialize communication manager (master)
    this.initCommunicationManager();
    // loadTranslations(options.locale);
  }

  /** @internal */
  private initCommunicationManager() {
    IframeClientLoader.then((cbIframeClient) => cbIframeClient.createMasterFrame());
  }

  setBusinessEntity(businessEntityId: string): Promise<ResponseInnerMessage> {
    return sendToMasterIframe('setBusinessEntity', {
      businessEntityId,
    });
  }

  /**
   * Returns an instance of Components SDK
   * @param options
   */
  components(options: unknown) {
    return new Components(options, EnvConstants.COMPONENTS);
  }

  load(chunkName: string) {
    switch (chunkName) {
      case 'components':
        return this.loadComponentsModule();
      case '3ds-handler':
        return this.load3DSHandler().then(() => true);
      case 'functions':
        return this.loadFunctionsPlugin();
      case 'ideal':
        return this.loadIDeal();
      case 'sofort':
        return this.loadSofort();
      case 'google-pay':
        return this.loadGooglePay();
      case 'bancontact':
        return this.loadBancontact();
      case 'giropay':
        return this.loadGiropay();
      case 'dotpay':
        return this.loadDotpay();
      case 'paypal':
        return this.loadPaypal();
      case 'netbanking_emandates':
        return this.loadNetbanking();
      case 'apple-pay':
        return this.loadApplepay();
      case 'upi':
        return this.loadUpi();
      case 'direct_debit':
        return this.loadDirectDebit();
      case 'boleto':
        return this.loadBoleto();
      case 'amazon-pay':
        return this.loadAmazonpay();
      case 'venmo':
        return this.loadVenmo();
      case 'faster_payments':
        return this.loadFasterPayments();
      case 'pay_to':
        return this.loadPayTo();
      case 'sepa_instant_transfer':
        return this.loadSepaInstantTransfer();
      case 'klarna_pay_now':
        return this.loadKlarnaPayNow();
      case 'online_banking_poland':
        return this.loadOnlineBankingPoland();
      default:
        throw new Error('Module ' + chunkName + ' not supported');
    }
  }

  /** @internal */
  loadFunctionsPlugin() {
    if (this.functionsPluginLoader) return Promise.resolve(this.functionsPluginLoader);
    return import(/* webpackChunkName: "functions-plugin-loader" */ '@/plugins/functions/loader').then(() => {
      this.estimates = this.functionsPluginLoader.estimates;
      this.vat = this.functionsPluginLoader.vat;
    });
  }

  /** @internal */
  load3DSHandler(): Promise<ThreeDSHandler> {
    if (this.threeDSLoader) return Promise.resolve(this.threeDSLoader.init());

    return import(/* webpackChunkName: "three-ds-loader" */ '@/plugins/three_domain_secure/loader')
      .then(() => this.threeDSLoader.loaderPromise)
      .then(() => this.threeDSLoader.init());
  }

  /** @internal */
  loadIDeal(): Promise<IDealPayment> {
    if (this.iDealPaymentLoader) return Promise.resolve(this.iDealPaymentLoader.init());

    return import(/* webpackChunkName: "iDeal-loader" */ '@/plugins/payments/iDeal/loader')
      .then(() => this.iDealPaymentLoader.loaderPromise)
      .then(() => this.iDealPaymentLoader.init());
  }

  /** @internal */
  loadSofort(): Promise<SofortPayment> {
    if (this.sofortPaymentLoader) return Promise.resolve(this.sofortPaymentLoader.init());

    return import(/* webpackChunkName: "sofort-loader" */ '@/plugins/payments/sofort/loader')
      .then(() => this.sofortPaymentLoader.loaderPromise)
      .then(() => this.sofortPaymentLoader.init());
  }

  /** @internal */
  loadGooglePay(): Promise<GooglePayment> {
    if (this.googlePaymentLoader) return Promise.resolve(this.googlePaymentLoader.init());

    return import(/* webpackChunkName: "google-pay-loader" */ '@/plugins/payments/google_pay/loader')
      .then(() => this.googlePaymentLoader.loaderPromise)
      .then(() => this.googlePaymentLoader.init());
  }

  /** @internal */
  loadBancontact(): Promise<BancontactPayment> {
    if (this.bancontactPaymentLoader) return Promise.resolve(this.bancontactPaymentLoader.init());

    return import(/* webpackChunkName: "bancontact-loader" */ '@/plugins/payments/bancontact/loader')
      .then(() => this.bancontactPaymentLoader.loaderPromise)
      .then(() => this.bancontactPaymentLoader.init());
  }

  /** @internal */
  loadGiropay(): Promise<GiropayPayment> {
    if (this.giropayPaymentLoader) return Promise.resolve(this.giropayPaymentLoader.init());

    return import(/* webpackChunkName: "giropay-loader" */ '@/plugins/payments/giropay/loader')
      .then(() => this.giropayPaymentLoader.loaderPromise)
      .then(() => this.giropayPaymentLoader.init());
  }

  /** @internal */
  loadDotpay(): Promise<DotpayPayment> {
    if (this.dotpayPaymentLoader) return Promise.resolve(this.dotpayPaymentLoader.init());

    return import(/* webpackChunkName: "dotpay-loader" */ '@/plugins/payments/dotpay/loader')
      .then(() => this.dotpayPaymentLoader.loaderPromise)
      .then(() => this.dotpayPaymentLoader.init());
  }

  /** @internal */
  loadPaypal(): Promise<PaypalPayment> {
    if (this.paypalPaymentLoader) return Promise.resolve(this.paypalPaymentLoader.init());

    return import(/* webpackChunkName: "paypal-loader" */ '@/plugins/payments/paypal_express_checkout/loader')
      .then(() => this.paypalPaymentLoader.loaderPromise)
      .then(() => this.paypalPaymentLoader.init());
  }

  /** @internal */
  loadVenmo(): Promise<VenmoPayment> {
    if (this.venmoPaymentLoader) return Promise.resolve(this.venmoPaymentLoader.init());

    return import(/* webpackChunkName: "venmo-loader" */ '@/plugins/payments/venmo/loader')
      .then(() => this.venmoPaymentLoader.loaderPromise)
      .then(() => this.venmoPaymentLoader.init());
  }

  /** @internal */
  loadNetbanking(): Promise<NetbankingPayment> {
    if (this.netbankingPaymentLoader) return Promise.resolve(this.netbankingPaymentLoader.init());

    return import(/* webpackChunkName: "dotpay-loader" */ '@/plugins/payments/netbanking_emandates/loader')
      .then(() => this.netbankingPaymentLoader.loaderPromise)
      .then(() => this.netbankingPaymentLoader.init());
  }

  /** @internal */
  loadApplepay(): Promise<ApplePayment> {
    if (this.applepayPaymentLoader) return Promise.resolve(this.applepayPaymentLoader.init());

    return import(/* webpackChunkName: "applepay-loader" */ '@/plugins/payments/apple_pay/loader')
      .then(() => this.applepayPaymentLoader.loaderPromise)
      .then(() => this.applepayPaymentLoader.init());
  }

  loadAmazonpay(): Promise<AmazonPayPayment> {
    if (this.amazonpayPaymentLoader) return Promise.resolve(this.amazonpayPaymentLoader.init());

    return import(/* webpackChunkName: "amazonpay-loader" */ '@/plugins/payments/amazon_payments/loader')
      .then(() => this.amazonpayPaymentLoader.loaderPromise)
      .then(() => this.amazonpayPaymentLoader.init());
  }

  /** @internal */
  loadUpi(): Promise<UpiPayment> {
    if (this.upiPaymentLoader) return Promise.resolve(this.upiPaymentLoader.init());

    return import(/* webpackChunkName: "upi-loader" */ '@/plugins/payments/upi/loader')
      .then(() => this.upiPaymentLoader.loaderPromise)
      .then(() => this.upiPaymentLoader.init());
  }

  /** @internal */
  loadDirectDebit(): Promise<DirectDebitPayment> {
    if (this.directDebitPaymentLoader) return Promise.resolve(this.directDebitPaymentLoader.init());

    return import(/* webpackChunkName: "direct-debit-loader" */ '@/plugins/payments/direct_debit/loader')
      .then(() => this.directDebitPaymentLoader.loaderPromise)
      .then(() => this.directDebitPaymentLoader.init());
  }

  /** @internal */
  loadFasterPayments(): Promise<FasterPymtsPayment> {
    if (this.fasterPymtsPaymentLoader) return Promise.resolve(this.fasterPymtsPaymentLoader.init());

    return import(/* webpackChunkName: "faster-payments-loader" */ '@/plugins/payments/faster_payments/loader')
      .then(() => this.fasterPymtsPaymentLoader.loaderPromise)
      .then(() => this.fasterPymtsPaymentLoader.init());
  }

  /** @internal */
  loadPayTo(): Promise<PayToPayment> {
    if (this.payToPaymentLoader) return Promise.resolve(this.payToPaymentLoader.init());

    return import(/* webpackChunkName: "pay-to-loader" */ '@/plugins/payments/pay_to/loader')
      .then(() => this.payToPaymentLoader.loaderPromise)
      .then(() => this.payToPaymentLoader.init());
  }

  /** @internal */
  loadSepaInstantTransfer(): Promise<SepaInstantTransferPayment> {
    if (this.sepaInstantTransferPaymentLoader) return Promise.resolve(this.sepaInstantTransferPaymentLoader.init());

    return import(
      /* webpackChunkName: "sepa-instant-transfer-loader" */ '@/plugins/payments/sepa_instant_transfer/loader'
    )
      .then(() => this.sepaInstantTransferPaymentLoader.loaderPromise)
      .then(() => this.sepaInstantTransferPaymentLoader.init());
  }

  /** @internal */
  loadBoleto(): Promise<BoletoPayment> {
    if (this.boletoPaymentLoader) return Promise.resolve(this.boletoPaymentLoader.init());

    return import(/* webpackChunkName: "boleto-loader" */ '@/plugins/payments/boleto/loader')
      .then(() => this.boletoPaymentLoader.loaderPromise)
      .then(() => this.boletoPaymentLoader.init());
  }

  /** @internal */
  loadKlarnaPayNow(): Promise<KlarnaPayNowPayment> {
    if (this.klarnaPayNowLoader) return Promise.resolve(this.klarnaPayNowLoader.init());
    return import(/* webpackChunkName: "klarna-pay-now-loader" */ '@/plugins/payments/klarna_pay_now/loader')
      .then(() => this.klarnaPayNowLoader.loaderPromise)
      .then(() => this.klarnaPayNowLoader.init());
  }

  /** @internal */
  loadOnlineBankingPoland(): Promise<OnlineBankingPolandPayment> {
    if (this.onlineBankingPolandPaymentLoader) {
      return Promise.resolve(this.onlineBankingPolandPaymentLoader.init());
    }

    return import(
      /* webpackChunkName: "online-banking-poland-loader" */ '@/plugins/payments/online_banking_poland/loader'
    )
      .then(() => this.onlineBankingPolandPaymentLoader.loaderPromise)
      .then(() => this.onlineBankingPolandPaymentLoader.init());
  }

  /** @internal */
  private loadComponentsModule() {
    if (this.componentLoader) return Promise.resolve(true);

    return import(/* webpackChunkName: "components-fields-loader" */ '@/plugins/components_fields/loader').then(
      (ComponentsAndFieldsLoader) => {
        if (!this.componentLoader) {
          this.componentLoader = new ComponentsAndFieldsLoader.default();
        }
        this.setReferrerModule('components_fields');
        return this.componentLoader.loaderPromise;
      }
    );
  }

  /** @internal */
  private loadHostedPagePlugin() {
    if (this.hostedPagePlugin) {
      return Promise.resolve(true);
    }
    return import(/* webpackChunkName: "hosted-page-loader" */ '@/plugins/hosted_page/host/impl').then(() => {
      return this.hostedPagePlugin;
    });
  }

  /** @internal */
  // Set referrerModule (for tracking)
  setReferrerModule(referrerModule: string) {
    if (typeof referrerModule == 'string' && referrerModule.trim()) {
      this.options.referrerModule = referrerModule;
    }
  }

  setPortalSession(ssoToken: (() => Promise<PortalSession>) | string): void {
    if (typeof ssoToken === 'function') {
      this.authHandler.setSsoTokenFetcher(ssoToken);
    } else {
      this.authHandler.setSsoToken(ssoToken);
    }
  }

  openCheckout(options): void {
    if (options.hostedPage || options.hostedPageUrl || options.url) {
      Helpers.resetFlags();
      var page = new Page(PageType.CHECKOUT, options);
      Handler.submit(page);
    } else {
      this.cart.proceedToCheckout();
    }
  }

  createCheckout(options: CheckoutOptions): Promise<Checkout> {
    return this.loadHostedPagePlugin().then(() => {
      return this.hostedPagePlugin.loadCheckout(options);
    });
  }

  createChargebeePortal(): ChargebeePortal {
    return new ChargebeePortal(this);
  }

  /** @internal */
  needsSsoAuthentication(pageType: PageType): boolean {
    return pageType == PageType.PORTAL ? !!this.authHandler.ssoToken || !!this.authHandler.ssoTokenFetcher : false;
  }

  logout(): void {
    this.authHandler.logout();
  }

  closeAll(): void {
    Handler.reset();
  }

  setLocale(locale: Locale) {
    this.options.locale = locale;
    // loadTranslations(this.options.locale);
  }

  /** @internal */
  getSiteInfo(): Promise<JsInfo> {
    return Handler.getJSInfo();
  }

  /** @internal */
  setStyle(style: JsInfo) {
    if (style) {
      this.styleConfig.image = style.image && style.image.url;
      this.styleConfig.color = style.color;
      this.styleConfig.layout = style.default_hp_layout;
    }
  }

  getCart() {
    return this.cart;
  }

  initializeProduct(planId: string, planQuantity?: number): Product {
    return new Product(planId, planQuantity, this.isItemsModel);
  }

  getProduct(element: CbAnchorElement): Product {
    return element.cbProduct;
  }

  setPortalCallbacks(callbacks: CbCallbacksInterface): void {
    this.portalCallbacks = callbacks;
  }

  setCheckoutCallbacks(callbacks: (cart: Cart) => CbCallbacksInterface): void {
    this.checkoutCallbacks = callbacks;
  }

  createComponent(componentType: ComponentType = ComponentType.Card, options = {}): Component {
    Assert.notTrue(() => this.componentLoader != null, 'modules not loaded');
    return this.componentLoader.createComponent(componentType, options);
  }

  tokenize(component: Component | ComponentType.Bank, payload = {}): Promise<any> {
    return this.componentLoader.tokenize(component, payload);
  }

  authorizeWith3ds(
    component: Component,
    intent: PaymentIntent,
    additionalData: AdditionalData,
    callbacks: Callbacks
  ): Promise<PaymentIntent> {
    return this.componentLoader.authorizeWith3ds(component, intent, additionalData, callbacks);
  }

  create3DSHandler(): ThreeDSHandler {
    if (this.threeDSLoader) return this.threeDSLoader.init();

    Assert.notTrue(() => this.threeDSLoader != null, 'module not loaded');
    return this.threeDSLoader.init();
  }

  /** @internal */
  loadComponent(componentType: ComponentType, options = {}): Promise<Component> {
    return this.loadComponentsModule().then(() => {
      return this.componentLoader.createComponent(componentType, options);
    });
  }

  /** @internal */
  initializeCaptcha(options: RecaptchaOptions) {
    return IframeClientLoader.then((cbIframeClient) =>
      cbIframeClient.send(
        {
          action: Master.Actions.InitializeCaptcha,
          data: {
            siteKey: options.siteKey,
            provider: options.provider,

            // Added for Backwards compatibility
            isGlobalRecaptcha: options.isGlobalRecaptcha,
            hideBanner: options.hideBanner,
          },
        },
        Ids.MASTER_FRAME,
        {timeout: 120000}
      )
    );
  }

  /** @internal */
  initializeRecaptcha(siteKey: string, isGlobalRecaptcha: boolean, hideBanner: boolean): Promise<any> {
    return this.initializeCaptcha({
      siteKey,
      isGlobalRecaptcha,
      hideBanner,
      provider: CaptchaProviderName.GOOGLE_RECAPTCHA,
    });
  }

  /** @internal */
  validateRecaptcha(action: string): Promise<any> {
    return IframeClientLoader.then((cbIframeClient) =>
      cbIframeClient.send(
        {
          action: Master.Actions.GenerateCaptchaToken,
          data: {
            action,
          },
        },
        Ids.MASTER_FRAME,
        {timeout: 120000}
      )
    );
  }

  /** @internal */
  skipPopup(paymentType: string, options: PaymentOptions) {
    return paymentType == 'pay_to' && !(options.paymentInfo && options.paymentInfo.useGateway);
  }

  handlePayment(paymentType: string, options: PaymentOptions): Promise<any> {
    let windowManager;
    if (
      paymentType in TabRedirectPayments &&
      !options.redirectMode &&
      !options.iframeMode &&
      !this.skipPopup(paymentType, options)
    ) {
      /**
       * Browsers will block new tabs/ windows which are not opened on user action
       * As a workaround, a blank tab is opened first and after fetching the URL from server
       * it is loaded in the blank tab
       */
      windowManager = new CbWindowManager();
      windowManager.openDirect('', PAYMENT_AUTH_REDIRECT_WINDOW_NAME, {
        skipReferrer: true,
        showLoader: true,
        openInNewWindow: true,
      });
    }
    switch (paymentType) {
      case 'ideal':
      case 'sofort':
      case 'giropay':
      case 'dotpay':
      case 'bancontact':
      case 'netbanking_emandates':
      case 'direct_debit':
      case 'boleto':
      case 'faster_payments':
      case 'pay_to':
      case 'sepa_instant_transfer':
      case 'klarna_pay_now':
      case 'online_banking_poland':
      case 'amazon_pay':
        return this.load(paymentType).then((module) => {
          if (options.redirectMode) {
            module.setRedirectMode(true);
          } else {
            module.setWindowManager(windowManager);
          }
          /**
           * providing backward compatibility to bancontact handler
           * which expects arguments in different order
           */
          if (['netbanking_emandates'].includes(paymentType)) {
            return module.handlePayment(options.paymentInfo, options.callbacks);
          }
          /**
           * Support callback flow for online banking poland
           * https://www.chargebee.com/checkout-portal-docs/online-banking-poland-tutorial.html
           *
           */
          if (['online_banking_poland'].includes(paymentType)) {
            if (options.paymentInfo && options.callbacks) {
              return module.handlePayment(options.paymentInfo, options.callbacks);
            }
          }
          return module.handlePayment(options);
        });
      default:
        return Promise.reject(new Error('unsupported_payment_type'));
    }
  }

  /** @internal */
  setHpToken(cbHpToken) {
    this.hp_token = cbHpToken;
  }

  /** @internal */
  getHpToken() {
    return this.hp_token;
  }
}
